<template>
    <div>
        <el-dialog
            custom-class="tipsDialog"
            title="温馨提示"
            width="430px"
            :visible.sync="showTurnCallDialog"
            :modal="false"
            :close-on-click-modal="false"
            @close="$emit('update:showTurnCallDialog',false)"
        >
            <div>此操作将生成新的轮呼列表，之前的轮呼列表将会被替换</div>
            <div>请选择想要轮呼的对象</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="$emit('useNewList')">新列表</el-button>
                <el-button type="primary" plain @click="$emit('useOldList')">原列表</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'TurnCallDialog',
    props: {
        showTurnCallDialog: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .tipsDialog {
    .el-dialog__header {
        text-align: center;
        span {
            font-size: 16px;
        }
    }
    .el-dialog__body {
        padding: 10px 40px;
        text-align: center;
        div:nth-child(1) {
            padding-bottom: 20px;
        }
    }
    .el-dialog__footer {
        text-align: center;
    }
}
</style>