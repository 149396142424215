<template>
    <div ref="page" class="box">
        <div style="text-align: center;color: #7F7F7F;font-size: 16px;margin: 23px 0 10px">公共池</div>
        <div style="height:100%">
            <div class="searchOpen">
                <search
                    style="margin-top:10px"
                    :is-public="true"
                    :show-depart-sea="true"
                    :label-arr="labelArr"
                    @clickData="clickData"
                />
            </div>

            <!-- style=" height:calc(100vh - 180px)" -->
            <el-card class="main" :style="{'margin-top': '10px' }">
                <!-- 搜索区域 -->
                <div class="search flex-sb">
                    <div class="RandomCallInTurn">
                        <el-button type="primary" @click="randomCall">随机轮呼</el-button>
                    </div>
                    <div class="top-search">
                        <el-input
                            v-model="status.keyword"
                            clearable
                            size="small"
                            placeholder="请输入姓名/手机号"
                            @keypress.native.enter="findData"
                            @clear="findData"
                        />
                        <!-- <i class="el-icon-search"></i> -->
                        <div class="icon-area" @click="findData">
                            <img width="20" height="20" src="@/assets/common/search.png" alt />
                        </div>
                    </div>
                    <div class="flex-sb right-time">
                        <div>流入公共池时间</div>
                        <div>
                            <el-radio-group v-model="timeTabs" size="small" @change="changeTime">
                                <el-radio-button label="0">全部</el-radio-button>
                                <el-radio-button label="1">今日</el-radio-button>
                                <el-radio-button label="2">昨日</el-radio-button>
                                <el-radio-button label="3">最近一周</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div>
                            <el-date-picker
                                v-model="valuetime"
                                size="small"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="timestamp"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            />
                        </div>
                    </div>
                </div>

                <el-table
                    v-loading="loading"
                    class="table"
                    style="margin:10px 0"
                    :data="tableData"
                    border
                    :height="tableHeight(332)"
                >
                    <el-table-column label="序号" type="index" width="50" />
                    <el-table-column label="分类" width="80">
                        <template slot-scope="scope">
                            <!-- {{ scope.row.category }} -->
                            <!-- <embed
                src="../../assets/clue/companyPool.svg"
                type="image/svg+xml"
                            />-->
                            <!-- <object src="../../assets/clue/companyPool.svg" type="image/svg+xml"> -->
                            <div class="flex" style="align-items:center">
                                <img
                                    v-if="scope.row.category == 0"
                                    width="60"
                                    src="@/assets/clue/companyPool.png"
                                    alt
                                />
                                <img
                                    v-if="scope.row.category == 1"
                                    width="60"
                                    src="@/assets/clue/departmentPool.png"
                                    alt
                                />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="客户姓名" width="120" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tooltip
                                v-model="scope.row.showTooltip"
                                placement="top"
                                :open-delay="500"
                                effect="dark"
                                :disabled="!scope.row.showTooltip"
                            >
                                <div slot="content">{{ scope.row.name }}</div>
                                <div class="flex" @mouseenter="showTips($event, scope.row)">
                                    <span>{{ scope.row.name }}</span>
                                    <div v-if="scope.row.newClient == 1" class="new">新</div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="客户资质" width="190">
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.hasHouse == 1"
                                class="icon house"
                                style="margin-right: 10px"
                            >房</span>
                            <span
                                v-if="scope.row.hasVehicle == 1"
                                class="icon car"
                                style="margin-right: 10px"
                            >车</span>
                            <span
                                v-if="scope.row.hasShebao == 1"
                                style="margin-right: 10px"
                                class="icon shebao"
                            >社</span>
                            <span
                                v-if="scope.row.hasGongjijin == 1"
                                class="icon gongjijin"
                                style="margin-right: 10px"
                            >金</span>
                            <span
                                v-if="scope.row.hasBaodan == 1"
                                style="margin-right: 10px"
                                class="icon baodan"
                            >保</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="资金需求" min-width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.fundNeeds }}万</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="线索状态" />
                    <el-table-column prop="source" label="渠道来源" />
                    <el-table-column prop="lastFollowedTime" label="最后跟进时间" width="160" />
                    <el-table-column prop="transferRemainTime" label="流入公司池剩余时间" min-width="160" />
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button
                                slot="reference"
                                size="mini"
                                type="primary"
                                @click="handleDelete(scope.$index, scope.row)"
                            >抓取</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="block" style="text-align: right">
                    <el-pagination
                        :current-page="page"
                        :page-size="pageLimit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="len"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </el-card>
        </div>
        <turn-call-dialog
            :show-turn-call-dialog.sync="showTurnCallDialog"
            @useNewList="useNewList"
            @useOldList="useOldList"
        />
        <random-call-size-dialog :show-dialog.sync="callSizeDialog" @clickSure="clickSure" />
    </div>
</template>
<script>
import search from '@/components/templates/search-open';
import RandomCallSizeDialog from '@/components/RandomCallSizeDialog';
import { clue_Public, clue_owner } from '@/api/clue_pool';
import { getYMDHMS } from '@/assets/js/time.js';
import elementResizeDetectorMaker from 'element-resize-detector';
import commonAlert from '@/mixins/commonAlert.js';
import clueBulkCall from '@/mixins/clueBulkCall.js';
import resize from '@/mixins/resize';
export default {
    components: {
        search,
        RandomCallSizeDialog,
    },
    mixins: [clueBulkCall, commonAlert, resize],
    data() {
        return {
            callSizeDialog: false,
            pageType: 1, //他是一个静态属性，用于帮助混淆识别页面，如果你不知道他的真实作用，请不要更改他
            radio: 0,
            // list: ["超时新线索", "超时未确认", "无效线索"],
            tabData: ['超时新线索', '超时未确认', '无效线索'],
            isShow: 0,
            activeName: 0,
            valuetime: '', //时间段搜索
            timeTabs: 0,
            tableData: [],
            labelArr: ['客户资质', '资金需求', '线索状态', '新老客'],
            status: {
                keyword: '', //input搜索关键词
                qualityType: '', //资质类型
                startTime: '', //开始时间
                endTime: '', //结束时间
                status: '', //线索状态
                newClient: '', //新老客户
                orderBy: '',
                fundNeedsScopeType: '', //资金需求
                pageNo: 1, //页码数
                pageSize: 10, //数据量
            },
            page: 1,
            len: 0,
            pageLimit: 10,
            loading: false,
            // clientHeight: this.$refs.page.offsetHeight,
            // tableHeight: window.innerHeight - 310,
            searchOpenHeight: '',
            onRiseHeight: window.innerHeight - 330,
        };
    },
    watch: {
        // clientHeight(val) {
        //   console.log("页面", val);
        // },
    },
    // computed: {
    // 	pageHeight() {
    // 		return window.innerHeight - 200 - this.searchOpenHeight + "px";
    // 	},
    // 	tableHeight() {
    // 		return window.innerHeight - 325 - this.searchOpenHeight + "px";
    // 	},
    // },
    created() {
        window.addEventListener('resize', this.watchSize);
        // window.addEventListener("resize", this.getHeight);
        //刷新后还是在这个标签
        let c = sessionStorage.getItem('path2');
        if (c) {
            this.isShow = c;
            this.activeName = c;
        }
        this.getTableDta();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.watchSize);
        // window.removeEventListener('resize', this.getHeight);
    },
    mounted() {
        this.watchSize();
    },
    methods: {
        randomCall() {
            if (this.callServices.isDisabled()) {
                this.alertCallServicesNotOpen();
                return;
            }
            this.getHighSeasClueStochasticCallList();
        },
        // 随机轮呼
        clickSure(radio) {
            this.radio = radio;
            this.callSizeDialog = false;
            this.jumpTurnCallPage();
        },
        // 页面高度或者宽度发生变化
        watchSize() {
            const _this = this;
            const erd = elementResizeDetectorMaker({
                strategy: 'scroll',
            });
            erd.listenTo(
                document.getElementsByClassName('searchOpen'),
                (element) => {
                    let height = element.offsetHeight;
                    _this.$nextTick(() => {
                        _this.searchOpenHeight = height - 50;
                        //监听到事件后执行的业务逻辑
                        // _this.searchOpenHeight = height - 50;
                        // console.log(_this.tableHeight - height -50 + 'px');
                        // _this.tableHeight = _this.tableHeight - height - 50 + "px";
                        // console.log(_this.tableHeight);
                    });
                }
            );
        },
        showTips(obj, row) {
            /*obj为鼠标移入时的事件对象*/

            /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
            let TemporaryTag = document.createElement('span');
            TemporaryTag.innerText = row.remark;
            TemporaryTag.className = 'getTextWidth';
            document.querySelector('body').appendChild(TemporaryTag);
            let currentWidth =
                document.querySelector('.getTextWidth').offsetWidth;
            document.querySelector('.getTextWidth').remove();

            /*cellWidth为表格容器的宽度*/
            const cellWidth = obj.target.offsetWidth;

            /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
            currentWidth <= 2 * cellWidth
                ? (row.showTooltip = false)
                : (row.showTooltip = true);
        },
        getHeight() {
            // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
            this.tableHeight = window.innerHeight - 310;
        },
        getTableDta() {
            clue_Public(this.status).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    this.loading = true;
                    let data = res.data.list;
                    // 查找字典code为clue-status
                    let clueStatus = this.common.allValueNeed('clue-status');
                    data.forEach((item) => {
                        // console.log(item);
                        if (Array.isArray(clueStatus)) {
                            clueStatus.forEach((i) => {
                                if (item.status == i.number) {
                                    item.status = i.cnVal;
                                }
                            });
                        }
                        item.lastFollowedTime = getYMDHMS(
                            item.lastFollowedTime
                        );
                    });
                    // console.log(data);
                    this.tableData = data;
                    this.len = res.data.total;
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // changeFixed(clientHeight) {
        //   if (this.$refs.page) {
        //     console.log("clientHeight", clientHeight);
        //     this.$refs.page.style.minHeight = clientHeight - 300 + "px";
        //   }
        // },
        // ck(e) {
        //   this.isShow = e;
        //   //存储这一次点击保存的标签
        //   sessionStorage.setItem("path2", e);
        // },
        selectInfo(val) {
            let index = val.index;
            this.isShow = index;
            sessionStorage.setItem('path2', index);
        },
        // 选择时间筛选
        changeTime() {
            console.log(this.timeTabs);
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            console.log('this.valuetime', this.valuetime);
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.status.pageNo = 1;
            this.page = 1;
            this.getTableDta();
        },
        // 时间选择
        time(val) {
            console.log(val);
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';

                // this.handleCurrentChange(1);
            } else {
                this.timeTabs = '';
                this.valuetime = val;

                // this.handleCurrentChange(1);
            }
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.handleCurrentChange(1);
        },
        //分页选择
        handleSizeChange(val) {
            console.log(val);
            this.size = val;
            this.status.pageSize = val;
            this.getTableDta();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.getTableDta();
        },
        // 搜索触发事件
        findData() {
            this.status.pageNo = 1;
            // console.log(this.status.pageNo,'this.status.pageNo')
            this.page = 1;
            this.getTableDta();
        },
        // search组件传值事件
        clickData(val) {
            console.log('父', val);
            this.status.qualityType = val.qualityType;
            this.status.fundNeedsScopeType = val.fundNeedsScopeType;
            this.status.newClient =
                val.newClient == 1 ? 1 : val.newClient == 2 ? 0 : '';
            this.status.status = val.clueStatus;
            this.status.orderBy = val.orderBy;
            this.status.departmentId = val.departSeaVal;
            this.getTableDta();
        },
        // 抓取
        handleDelete(index, row) {
            clue_owner({
                clueId: row.id,
            }).then((res) => {
                if (res.code === '0') {
                    this.$message.success('抓取成功');
                    this.getTableDta();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import '@/css/table.less';

.flex {
    display: flex;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #333;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search {
    .RandomCallInTurn {
        position: absolute;
    }
    .top-search {
        position: relative;
        transform: translateX(100px);
        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 8px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}

.table-area {
    margin-top: 10px;
}

.box {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    // overflow: scroll;

    .main {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    // .list span {
    //   display: inline-block;
    //   width: 120px;
    //   height: 40px;
    //   background: rgba(255, 255, 255, 1);
    //   box-shadow: 0px 2px 4px 0px rgba(14, 5, 9, 0.2);
    //   border-radius: 4px;
    //   text-align: center;
    //   line-height: 40px;
    //   cursor: pointer;
    // }
    // .list span.active {
    //   background: #4086ec;
    //   color: #fff;
    // }
}

.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}

/deep/ .el-input__clear {
    margin-right: 23px;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}

.el-popover {
    padding: 20px !important;
}
</style>
