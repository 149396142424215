export default {
    methods: {
        //  未开通呼叫功能提示
        alertCallServicesNotOpen() {
            this.$alert('您还未开通呼叫功能，请联系管理员。', '提示', {
                confirmButtonText: '确定',
            });
        },
    },
};
