<template>
    <div>
        <el-dialog
            custom-class="tipsDialog"
            title="随机轮呼"
            :visible="showDialog"
            :modal="false"
            :close-on-click-modal="false"
            width="430px"
            @close="$emit('update:showDialog',false)"
        >
            <div>请选择随机轮呼的条数：</div>
            <div>
                <el-radio v-model="radio" label="30">30条</el-radio>
                <el-radio v-model="radio" label="50">50条</el-radio>
                <el-radio v-model="radio" label="100">100条</el-radio>
                <el-radio v-model="radio" label="200">200条</el-radio>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="$emit('clickSure',radio)">确定</el-button>
                <el-button type="primary" plain @click="$emit('update:showDialog',false)">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'RandomCallSizeDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            radio: '30',
        };
    },
};
</script>

<style lang="less" scoped>
/deep/ .tipsDialog {
    .el-dialog__header {
        text-align: center;
        span {
            font-size: 16px;
        }
    }
    .el-dialog__body {
        padding: 10px 40px;
        div:nth-child(1) {
            padding-bottom: 20px;
        }
    }
}
</style>