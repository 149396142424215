import {
    getMyClueBatchFollow, // 获取我的线索轮呼列表
    createMyClueBatchFollow, // 创建我的线索轮呼列表
    getPublicClueRandomList, // 获取公共池线索随机轮呼列表
} from '@/api/my_clue';
import TurnCallDialog from '@/components/TurnCallDialog';
export default {
    data() {
        return {
            showTurnCallDialog: false,
        };
    },

    methods: {
        // 获取我的线索轮呼列表
        getMyClueCallList() {
            getMyClueBatchFollow().then((res) => {
                if (res.code !== '0') return console.log('获取我的线索轮呼列表失败');
                if (res.data.list.length === 0) {
                    if (this.pageType === 0) {
                        this.createMyClueCallList();
                    } else if (this.pageType === 2) {
                        this.jumpTurnCallPage();
                    }
                } else {
                    this.showTurnCallDialog = true;
                }
            });
            // this.showTurnCallDialog = true
        },
        // 创建我的线索轮呼列表
        createMyClueCallList() {
            let list = [];
            this.client_Ids.forEach((item) => {
                list.push({
                    id: item.id,
                    name: item.name,
                });
            });
            if (list.length === 0) {
                return this.$message.error('当前所选列表为空');
            }
            createMyClueBatchFollow(list).then((res) => {
                if (res.code !== '0') return console.log('创建我的线索轮呼列表失败');
                this.jumpTurnCallPage();
            });
        },
        // 获取公海线索随机轮呼列表
        getHighSeasClueStochasticCallList() {
            getPublicClueRandomList(0).then((res) => {
                if (res.code !== '0') return console.log('获取公海线索随机轮呼列表失败');
                if (res.data.list.length === 0) {
                    this.callSizeDialog = true;
                } else {
                    this.showTurnCallDialog = true;
                }
            });
            // this.callSizeDialog = true
        },
        // 使用新轮呼列表
        useNewList() {
            if (this.pageType === 0) {
                this.createMyClueCallList();
            } else if (this.pageType === 1) {
                this.callSizeDialog = true;
            }
            this.showTurnCallDialog = false;
            console.log('使用新轮呼列表');
        },
        // 使用旧轮呼列表
        useOldList() {
            this.showTurnCallDialog = false;
            this.jumpTurnCallPage();
            console.log('使用旧轮呼列表');
        },
        // 跳转轮呼页面
        jumpTurnCallPage() {
            this.$router.push({
                path: '/turns-call-list',
                query: {
                    type: 'clue',
                    pageType: this.pageType,
                    previousPath: this.$route.path,
                    size: this.radio || 0,
                },
            });
        },
    },
    components: {
        TurnCallDialog,
    },
};
